<template>
    <div id="QuestionnaireLog_">
        <CSBreadcrumb/>
        <div class="page-container">
            <div class="page-container-header">
                <CSTabBar :tabbar="tabBarList" :checkedTab="checkedTabBar" @changeTabBar="changeTabBar"></CSTabBar>
                <div>
                    <template v-if="checkedTabBar === 2">
                        <CSSelect
                            style="width: 185px; margin-right: 10px;"
                        >
                            <el-date-picker
                                v-model="startDate"
                                type="date"
                                format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd"
                                placeholder="请选择开始时间"
                                prefix-icon="el-icon-time"
                            ></el-date-picker>
                        </CSSelect>
                        <span
                            style="
                                    display: inline-block;
                                    vertical-align: middle;
                                    height: 2px;
                                    width: 20px;
                                    margin-right: 0;
                                    background-color: #999;
                                "
                        >
                        </span>
                        <CSSelect
                            style="width: 185px; margin-left: 10px;"
                        >
                            <el-date-picker
                                v-model="endDate"
                                type="date"
                                format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd"
                                placeholder="请选择结束时间"
                                prefix-icon="el-icon-time"
                            ></el-date-picker>
                        </CSSelect>
                    </template>
                    <input
                        type="text"
                        class="search_"
                        v-model="userInfo"
                        style="border-radius: 4px; padding-left: 10px;"
                        v-if="checkedTabBar === TAB_BAR_TYPE.COMMIT_LOG"
                        placeholder="搜索姓名/手机号"
                    />

                    <button
                        v-if="checkedTabBar === TAB_BAR_TYPE.COMMIT_LOG"
                        class="getQuery_"
                        @click="submitRecord()"
                    >
                        查询
                    </button>
                </div>
                <div
                    style="margin-top: 20px"
                    v-if="checkedTabBar === TAB_BAR_TYPE.STATISTICS"
                >
                    <span>
                        已发送人数：{{ statistics.sendCount || '-' }}
                    </span>
                    <span style="margin-left: 30px">
                        已查看人数：{{ statistics.uvCount || '-' }}
                    </span>
                    <span style="margin-left: 30px">
                        已提交人数： {{ statistics.submitCount || '-' }}
                    </span>
                </div>
            </div>
            <div class="page-container-content">
                <template v-if="checkedTabBar === TAB_BAR_TYPE.STATISTICS">
                    <div
                        class="question-item"
                        v-for="(question, indexs) in questions"
                        :key="indexs"
                    >
                        <div class="question-item-title">
                            {{ indexs + 1 }}、{{ question.question }}
                        </div>
                        <div class="question-table">
                            <table
                                v-if="question.type != QUESTION_TYPE.FILL_BLANK"
                            >
                                <thead>
                                    <tr>
                                        <th>选项</th>
                                        <th>小计</th>
                                        <th>百分比</th>
                                        <th>提交人</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="(
                                            item, index
                                        ) in question.options"
                                        :key="index"
                                    >
                                        <td>{{ item.option }}</td>
                                        <td>{{ item.total }}</td>
                                        <td>{{ item.percentage }}</td>
                                        <td>
                                            <template v-if="item.total > 0">
                                                <span
                                                    class="allow-click"
                                                    @click="
                                                        lookSubmitter(
                                                            question.id,
                                                            item.option
                                                        )
                                                    "
                                                    >查看</span
                                                >
                                            </template>
                                            <template v-else>-</template>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table v-else>
                                <thead>
                                    <tr>
                                        <th>序号</th>
                                        <th>答案文本</th>
                                        <th>提交人</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="(
                                            item, index2
                                        ) in question.options"
                                        :key="index2"
                                    >
                                        <td>{{ item.index }}</td>
                                        <td>{{ item.result }}</td>
                                        <td>
                                            <template
                                                v-if="
                                                    item.result &&
                                                    item.result != ''
                                                "
                                            >
                                                <span
                                                     class="allow-click"
                                                    @click="
                                                        lookSubmitter(
                                                            question.id,
                                                            item.result
                                                        )
                                                    "
                                                    >查看</span
                                                >
                                            </template>
                                            <template v-else>-</template>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="question-table">
                        <table>
                            <thead>
                                <tr>
                                    <th>提交时间</th>
                                    <th>身份</th>
                                    <th>姓名</th>
                                    <th>手机号</th>
                                    <th>问卷详情</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(item, index) in getList"
                                    :key="index"
                                >
                                    <td>{{ item.submitDate }}</td>
                                    <td>
                                        {{
                                            QUESTIONNAIRE_SEND_TYPE_STR[
                                                item.userType
                                            ]
                                        }}
                                    </td>
                                    <td>{{ item.userName }}</td>
                                    <td>{{ item.userPhone }}</td>
                                    <td>
                                        <span
                                            class="allow-click"
                                            @click="lookRecord(item)"
                                            >查看</span
                                        >
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </template>
            </div>
        </div>

        <!-- 问卷详情弹窗 -->
        <CSDialog
            dialogWidth="580px" dialog-header-class="alert-bg"
            dialogTitle="查看问卷详情"
            :dialogVisible="questionDetailModalInfo.visible"
            :dialogShowConfirmBtn="false"
            dialogCancelBtnText="关闭"
            @onClose="questionDetailModalInfo.visible = false"
        >
            <div
                slot="dialog-content"
                style="padding: 20px 30px; font-size: 24px;"
            >
                <div class="question-detail-modal-content-item" v-for="(item, index) in questionDetailModalInfo.data" :key="item.id">
                    <div class="question-detail-modal-content-item-title">
                        {{(index + 1) + '、' + item.question}}
                    </div>
                    <div class="question-detail-modal-content-item-content" v-if="item.type === 1 || item.type === 3">
                        回答：{{ judge(item.answer, item.type) }}
                    </div>
                    <template v-else>
                      <div class="question-detail-modal-content-item-content" v-for="answer in judge(item.answer, item.type)" :key="answer">
                        回答：{{ answer }}
                    </div>
                    </template>
                </div>
            </div>
        </CSDialog>
        <!-- 问卷统计潭出口部 -->
        <CSDialog
            dialogTitle="提交人"
            dialogWidth="960px"
            :dialogVisible="dialogVisible"
            dialogCancelBtnText="关闭" dialog-header-class="alert-bg"
            :dialogShowConfirmBtn="false"
            @onClose="dialogVisible = false"
        >
            <div
                slot="dialog-content"
                style="padding: 20px 30px; font-size: 24px;"
            >
                <table
                    class="footable table table-stripped toggle-arrow-tiny modal-inner-table"
                >
                    <thead>
                        <tr>
                            <th class="text-center">提交时间</th>
                            <th class="text-center">身份</th>
                            <th class="text-center">姓名</th>
                            <th class="text-center">手机号</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(submitter, submitterIndex) in submitterList"
                            :key="submitterIndex"
                        >
                            <td class="text-center">{{ submitter.submitDate }}</td>
                            <td class="text-center">
                                {{
                                    QUESTIONNAIRE_SEND_TYPE_STR[submitter.userType]
                                }}
                            </td>
                            <td class="text-center">{{ submitter.userName }}</td>
                            <td class="text-center">{{ submitter.userPhone }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </CSDialog>
    </div>
</template>

<script>
//  tabBar类别
import {
    adminQueryUrl,
    queryQuestionnaireCensusUrl,
    adminQueryCommitLogUrl,
    adminQueryOptionDetailUrl,
    userFillSurveyUrl,
} from "@/requestUrl";
import dayjs from "dayjs";
import {
    QUESTION_TYPE,
    QUESTIONNAIRE_SEND_TYPE,
    QUESTIONNAIRE_SEND_TYPE_STR,
} from "@/constant";
import CSTabBar from "@/components/common/CSTabBar";
import CSDialog from "@/components/common/CSDialog";
import CSBreadcrumb from "@/components/common/CSBreadcrumb";
import CSSelect from "@/components/common/CSSelect";

const TAB_BAR_TYPE = {
    STATISTICS: 1,
    COMMIT_LOG: 2,
};

export default {
    name: "questionnaireLog",
    props: {
        id: [Number, String],
    },
    filters: {
        getQuestionResult(result) {
            if (
                result &&
                result.includes("[") &&
                result.includes("]") &&
                result.includes(",")
            ) {
                return result
                    .replace("[", "")
                    .replace("]", "")
                    .replace(/"/g, "");
            }
            return result;
        },
    },
    components: {
        CSTabBar,
        CSDialog,
        CSBreadcrumb,
        CSSelect,
    },
    data() {
        return {
            QUESTION_TYPE,
            TAB_BAR_TYPE,
            QUESTIONNAIRE_SEND_TYPE,
            QUESTIONNAIRE_SEND_TYPE_STR,
            // 问题详情弹窗
            getList: [],
            // 统计弹窗显示与隐藏
            dialogVisible: false,
            questionDetailModalInfo: {
                visible: false,
                data: [],
                onClose: () => {
                    this.questionDetailModalInfo.visible = false;
                },
            },
            tabBarList: {
                [TAB_BAR_TYPE.STATISTICS]: "统计",
                [TAB_BAR_TYPE.COMMIT_LOG]: "提交记录",
            },
            checkedTabBar: TAB_BAR_TYPE.STATISTICS,
            // 搜索手机号对应的数据
            userInfo: "",
            startDate: new dayjs().subtract(30, "day").format("YYYY-MM-DD"),
            endDate: new dayjs().format("YYYY-MM-DD"),
            look_Record: false, // 控制问卷弹出框的显示与隐藏
            //统计的数据
            statistics: {
                sendCount: 0,
                uvCount: 0,
                submitCount: 0,
            },
            questions: [],
            submitterList: [],
        };
    },
    created() {
        this.getStatistics();
        this.submitRecord();
    },
    methods: {
        /**
         * 切换tabBar
         * @param {Number} type tabBar类型
         *  */
        changeTabBar(type) {
            this.checkedTabBar = type;
        },
        // 后台查询数据统计
        getStatistics() {
            this.$fly
                .post(queryQuestionnaireCensusUrl, {
                    regionCode: this.$vc.getCurrentRegion().code, //区域id 必填
                    id: this.id, //问卷id
                    // startDate: this.startDate, //查询开始时间
                    // endDate: this.endDate, //查询结束时间
                })
                .then((res) => {
                    if (res.code !== 0) {
                        return;
                    }
                    this.statistics = res.data;
                    this.questions = res.data.questions;
                });
        },

        // 后台查询提交记录
        submitRecord() {
            this.$fly
                .post(adminQueryCommitLogUrl, {
                    regionCode: this.$vc.getCurrentRegion().code, //区域id 必填
                    id: this.id, //问卷id
                    startDate: this.startDate, //查询开始时间 必填
                    endDate: this.endDate, //查询结束时间 必填
                    userInfo: this.userInfo, //根据用户姓名或手机号查询
                    // pageNo: 1, //页码 为空查询全部
                    // pageSize: 10, //每页显示条数 默认10
                })
                .then((res) => {
                    this.getList = res.data;
                    console.log(this.getList, "请求成功");
                });
        },
        // 后台查看提交人的接口
        lookSubmitter(questionId, answer) {
            this.$router.push({name: "Submitter", params: {id: questionId, answer, surveyId: this.id,}});
        },
        // 查看问卷提交详情
        async lookRecord(questionInfo) {
            const { userId } = questionInfo;
            let res = await this.$fly.post(userFillSurveyUrl, {
              reginCode: this.$vc.getCurrentRegion().code,
              id: this.id,
              userId,
            })
            if (res.code != 0) {
              return;
            }
            this.questionDetailModalInfo.visible = true;
            this.questionDetailModalInfo.data = res.data.questions;
        },
        // 判断
        judge(answer, type) {
          if (type === 1 || type === 3) {
            return answer
          } else if (type === 2) {
            let str = JSON.parse(answer);
            return str
          }
        }
    },
};
</script>

<style lang="stylus">
.question-detail-modal
    border-radius 10px
    box-shadow 0 -2px 0 0 rgba(0, 0, 0, 0.1)
    .el-dialog__header
        display none
#QuestionnaireLog_ .el-dialog__header
    padding 0 !important
#QuestionnaireLog_ .el-dialog__body
    padding-bottom 0 !important
#QuestionnaireLog_ .el-dialog__footer
    text-align center
</style>

<style lang="stylus" scoped>
.nav-bar
    width 100%
    background #FFFFFF
    box-shadow 0px 5px 10px 0px rgba(0, 0, 0, 0.1)
    font-size 24px
    color var(--pc-theme-color)
    padding 18px 30px
    box-sizing border-box
    div
        line-height 24px
        display inline-block
// 问题详情弹窗
.question-detail-modal
    border-radius 10px
    box-shadow 0 -2px 0 0 rgba(0, 0, 0, 0.1)
    .el-dialog__header
        display none
    &-content
        padding 0 10px
        font-size 24px
        &-item
            &-title
                color #999
                margin-bottom 10px
            &:not(:last-of-type)
                margin-bottom 30px
    &-footer
        padding 20px 0 10px 0
        text-align center
        position relative
        &::before
            position absolute
            top -10px
            left -20px
            right -20px
            content ' '
            height 2px
            background rgba(0, 0, 0, 0.1)
        .btn-cancel
            width 80px
            height 40px
            background #FF9F00
            box-shadow 0 5px 10px 0 rgba(255, 159, 0, 0.3)
            border-radius 6px
            color #fff
// 页面内容
.page-container
    background #fff
    margin 20px 30px
    box-shadow -10px 0 20px 0 rgba(0, 0, 0, 0.1)
    &-header
        padding 30px 30px 20px 30px
        font-size 20px
        .tab-bar
            div
                font-size 20px
                width 210px
    &-content
        border-top 1px solid #F0F0F0
        padding 30px
        color #444
        .question-item
            &:not(:last-of-type)
                margin-bottom 30px
            &-title
                font-size 20px
                margin-bottom 10px
                line-height 20px
// 问题表格
.question-table
    table
        width 100%
        border-radius 8px
        font-size 20px
        position relative
        overflow hidden
        &::before
            position absolute
            top 0
            left 0
            right 0
            bottom 0
            content ' '
            border 1px solid #ccc
            border-radius 8px
            z-index -1
        tbody
            position relative
            z-index 3
        th
            background #E4E7EB
        td, th
            height 48px
            border 1px solid #ccc
            text-align center
            vertical-align middle
.page-container .search_
    width 180px
    height 30px
    font-size 14px
    border 1px solid #979797
    border-radius 4px
        // padding-left 15px
    margin-left 20px
    vertical-align middle
.getQuery_
    width 50px
    height 30px
    background #1ab394
    box-shadow 0px 5px 10px 0px rgba(0, 182, 148, 0.2)
    border-radius 4px
    font-size 14px
    border 0
    color #fff
    margin-left 20px
    vertical-align middle
.close_
    width 80px
    eight 40px
    background #FF9F00
    box-shadow 0px 5px 10px 0px rgba(255, 159, 0, 0.3)
    border-radius 6px
    color #FFF
    font-size 20px
</style>
